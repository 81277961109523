@import '../../../constants/constants.css';
/* 
    CSS PREFACE - MaterialUI has extremely subborn inbuilt styling which could
    only be overwritten by using !important on every style which gets applied
    to a MaterialUI Component
*/

.container {
    margin-left: var(--SIDEBAR_WIDTH);
    margin-top: var(--TOOLBAR_HEIGHT);
    /* height: 80%; */
    /* padding: 2em 2em 6em 2em; */
    padding: 2em 2em 0 2em;
    overflow: auto;
    margin: calc(var(--TOOLBAR_HEIGHT) + 1em) 1em 10em var(--SIDEBAR_WIDTH);
}

.loaderContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background-color: rgba(120,120,120,0.4);
}

.dataTable {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    min-width: '1080px';
}
/* Hide scrollbar for Chrome, Safari and Opera */
.dataTable::-webkit-scrollbar {
    display: none;
  }
  
/* Hide scrollbar for IE, Edge and Firefox */
.dataTable {
-ms-overflow-style: none;  /* IE and Edge */
scrollbar-width: none;  /* Firefox */
}


.lowerBarContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    border-top: none;
    min-width: 1078px;
}

.downloadButton {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline;
}

.countContainer {
    background-color: #00af8c;
    width: fit-content;
    border-radius: 5px;
    margin: 1em;
    padding: 5px;
    color: white;
    font-size: 14px;
    cursor: default;
}

.paginationAndExport {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #e0e0e0;
    padding: 1em;
}

.dropdown {
    height: 30px;
    width: 120px;
}

.dropdown div:nth-child(1) {
    padding: 7px 10px;
}

.tableHeader {
    display: flex;
    min-width: fit-content!important;
    font-size: 12px;
}

.tableCell, .tableCellLarge {
    font-size: 12px;
}

.downloadPending {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.tableCellLarge:hover {
    display: flex;
    white-space: pre-wrap!important;
    overflow: auto!important;
    word-wrap: break-word;
    text-overflow: initial;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: var(--TOOLBAR_COLOR) #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 9px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--TOOLBAR_COLOR);
    border-radius: 10px;
    border: 3px solid #ffffff;
}

@media screen and (max-width: 1200px) {
    .container {
        margin-left: 10em;
    }
}

@media screen and (max-width: 960px) {
    .container {
        margin-left: 0em;
    }
}