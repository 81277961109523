.title {
    margin-top: 5em;
    text-align: center;
}

.form {
    width: 22em;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reminderText, .reminderTextValid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    color: gray
}

.reminderTextValid {
    color: green;
}

.submit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1em 1em 0 1em;
}

.forgotPassword {
    margin: 2em 0 4em 0;
    text-decoration: unset;
}

.redirectNotice {
    color: green;
}