@import '../../../constants/constants.css';

.footer {
    position: fixed;
    bottom: 0;
    width: calc(100% - var(--SIDEBAR_WIDTH) - 3em);
    z-index: 1000;
    padding: 0.5em;
    padding-left: 2.5em;
    border-top: 2px solid var(--SIDEBAR_COLOR);
    background-color: white;
    height: 8em;
    color: gray;
    margin-left: var(--SIDEBAR_WIDTH);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.copyright {
    display: flex;
    align-items: center;
    font-size: 10px;
}

.disclaimer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.warning {
    color: red;
    font-weight: bold;
}


@media screen and (max-width: 1200px) {
    .footer {
        margin-left: 10em;
        width: calc(100% - 10em - 3em);
    }
}

@media screen and (max-width: 960px) {
    .footer {
        margin-left: 0;
        width: calc(100% - 3em);
    }
}