@import '../../../constants/constants.css';

.root {
    margin-bottom: 6em;
    display: flex;
    flex-direction: row;
    user-select: none;
}

.container {
    width: 25em;
}

.infoIcon {
    margin-left: 0.5em;
    color: #ccc;
    cursor: pointer;
}

.selectedIconInfo {
    color: #00af8c;
}

.numberContainer {
    margin-left: 1.5em;
    cursor: pointer;
    padding: 0.1em;
    border: 0.2em solid white;
    border-radius: 1em;
}

.infoTextArea {
    height: 24em;
    width: 100%;
    user-select: none;
    border: none;
    background: none;
}

.selectedNumberContainer {
    border: 0.2em solid var(--TOOLBAR_COLOR);
    border-radius: 1em;
    padding: 0.1em;
}

.highlightText {
    color: TOOLBAR_COLOR;
    margin-bottom: 0.2em;
}

.marginTop {
    margin-top: 1em;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.title {
    margin-bottom: 1em;
    margin-left: 1.5em;
}

.helpTitle {
    color: TOOLBAR_COLOR;
    font-weight: bold;
    margin-bottom: 1em;
}

.help {
    margin-left: 24em;
    text-align: left;
    border: 1px solid black;
    border-radius: 0.5em;
    padding: 0.5em;
    background-color: whitesmoke;
    position: absolute;
    width: 22em;
    z-index: 100000;
}