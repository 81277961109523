@import '../../../constants/constants.css';

.toolbar {
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: calc(100% - var(--SIDEBAR_WIDTH));
    height: var(--TOOLBAR_HEIGHT);
    margin-left: var(--SIDEBAR_WIDTH);
    padding-top: 0.5em;
    padding-left: 2em;
    padding: 0.5em;
    background-color: var(--TOOLBAR_COLOR);
}

.logoutButtonHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
}

.toolbarTitle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.actionsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.impersonateContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 50px;
    cursor: pointer;
    color: gray;
}

.user {
    color: white;
    margin-right: 25px;
    cursor: default;
}

.logoutButton {
    color: white;
}

.title {
    color: white;
    font-size: 18px;
    font-weight: 500;
}

.hint {
    color: var(--TOOLBAR_HINT_COLOR);
    font-style: italic;
    font-size: 11px;
}

@media screen and (max-width: 1200px) {
    .toolbar {
        margin-left: 10em;
        width: calc(100% - 10em);
    }
}

@media screen and (max-width: 960px) {
    .toolbar {
        height: 2.5em;
        margin-left: 0em;
        padding: 0;
        padding-left: 10px;
        width: 100%;
    }

    .title {
        font-size: 14px;
    }
}

@media screen and (max-width: 560px) {
    .title, .hint {
        display: none;
    }

    .toolbar {
        display: flex;
        justify-content: center;
    }
}