.container {
    height: 100%;
    background: linear-gradient(180deg, rgba(145, 213, 221, 0.15) 70%, rgba(40, 37, 97, 0.15) 100%);
}

.main {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.details {
    margin-top: 1em;
}

.better {
    color: #00abbf;
}

.divider {
    border: 1px solid #ccc;
    height: 80%;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1em;
    flex: 1;
}

.title {
    font-size: 2em;
    font-family: "Montserrat; sans-serif";
}

.paragraph {
    color: #00af8c;
    font-family: "Montserrat; sans-serif";
    font-style: italic;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-bottom: 1em;
    text-align: center;
}