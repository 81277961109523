@import '../../../constants/constants.css';

.logo {
    width: 100%;
    text-align: center;
    cursor: pointer;
}

.image {
    width: 80%;
    margin-top: 2em;
}

.sidebar {

    display: block;
    width: var(--SIDEBAR_WIDTH);
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background-color: var(--SIDEBAR_COLOR);
    z-index: 1000;
}

@media screen and (max-width: 1200px) {
    .sidebar {
        width: 10em;
    }
}

@media screen and (max-width: 960px) {
    .sidebar {
        display: none;
    }
}

/* [theme.breakpoints.up("md")]: {
    display: "block"
}

[theme.breakpoints.between("md"; MD_HIGH)]: {
    width: 10em;
} */