.modalWrapper {
    /* position: absolute;
    top: 40px;
    left: 40px;
    right: 40px;
    bottom: 40px; */
    width: 450px;
    max-height: 350px;
    height: 100%;
    border-radius: 25px;
    padding: 25px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    background-color: white;
    z-index: 100000;
}

.dropdown {
    width: 170px;
}

.loaderContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
}

.submitButtons {
    display: flex;
    flex-direction: row;
    justify-self: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-top: auto
}

.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(100, 100, 100, 0.5);
}