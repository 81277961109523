.root {
    margin: 5em 1em 10em 15em;
    border: 1px solid #ccc;
    border-radius: 2em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1em;
    padding-left: 4em;
    padding-right: 4em;
}

.title {
    text-align: center;
    font-size: 22px;
    margin-bottom: 2em;
}

.formController {
    margin: 8px!important;
    width: 300px;
    align-items: center!important;
}

.main {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

.searchBar {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 0 0 3em 0;
}

.sectionContainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 400px;
    justify-content: space-evenly;
}

.rowSegmentContainer {
    margin-bottom: 3em;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.financialInput {
    border-radius: 2em !important;
    text-align: center;
    width: 80%;
    height: 56px;
}

.submit {
    position: sticky;
    bottom: 6em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.filterLink {
    text-decoration: none;
}

.filterButton {
    background-color: #00af8c !important;
    border-radius: 1.5em !important;
    text-transform: none;
    text-decoration: none;
}

.resetFiltersLink {
    font-weight: bold;
    margin-left: 1em;
    cursor: pointer;
}

@media screen and (max-width: 1415px) {
    .root {
        justify-content: center;
        align-items: center;
    }

    .main {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .formController {
        width: 500px;
    }

    .searchBar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 500px;
    }

    .rowSegmentContainer{
        margin-bottom: 3em;
    }
}

@media screen and (max-width: 1200px) {
    .root {
        margin-left: 11em
    }
}
@media screen and (max-width: 960px) {
    .root {
        margin-left: 1.5em
    }
}