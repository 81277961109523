.title {
    margin-top: 5em;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 25em;
    margin-bottom: 5em;
    margin-top: 1em;
}

.submit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1em 0 1em;
}