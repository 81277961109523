.title {
    margin-top: 5em;
    text-align: center;
}

.form {
    width: 22em;
}

.submit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em 1em 0 1em;
}

.forgotPassword {
    margin: 2em 0 4em 0;
    text-decoration: unset;
}