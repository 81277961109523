.container {
    background-color: rgb(112, 112, 112, 0.10999999940395355);
    padding: 0.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #00af8c;
}

.containerError {
    color: #FF0000;
}

.error {
    margin-right: 0.5em;
}